import ApplicationController from './application_controller'

export default class extends ApplicationController {

  search() {
    let input = document.getElementById("search-input");

    document.querySelectorAll('.homework-submission').forEach(function (submission) {
      if (submission.dataset.userName.includes(input.value)) {
        submission.classList.remove('hidden');
      } else {
        submission.classList.add('hidden');
      }
    });
  }

}
