import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
  }

  toggle() {
    let input = document.getElementById("user_password");
    let hideElement = document.getElementById('password-hide-link');
    let showElement = document.getElementById('password-show-link');

    if (input) {
      if (input.type === "password") {
        input.type = "text";
        showElement.classList.add('hidden');
        hideElement.classList.remove('hidden');
      } else {
        input.type = "password";
        showElement.classList.remove('hidden');
        hideElement.classList.add('hidden');
      }
    }
  }
}

