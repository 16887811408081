import ApplicationController from './application_controller'

export default class extends ApplicationController {
  //  TODO
  // connect() {
  //   // Set the connected Stripe Account to collect payments on behalf of that account
  //   const stripe = Stripe(this.element.dataset.stripeKey, {
  //     stripeAccount: this.element.dataset.stripeAccountId
  //   });

  //   this.initializeCheckout(stripe);
  // }

  // // Create a Checkout Session as soon as the page loads
  // async initializeCheckout(stripe) {
  //   const clientSecret = this.element.dataset.stripeCheckoutId;

  //   const checkout = await stripe.initEmbeddedCheckout({
  //     clientSecret,
  //   });

  //   // Mount Checkout
  //   checkout.mount('#checkout');
  // }
}
