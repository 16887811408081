import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    const htmlContainer = this.element;

    if (htmlContainer) {
      const htmlInput = document.getElementById(htmlContainer.dataset.htmlInput);
      if (htmlInput) {
        const editor = pell.init({ element: this.element,
                                   onChange: html => htmlInput.value = html,
                                   actions: [ 'bold',
                                              'underline',
                                              'italic',
                                              'heading1',
                                              'heading2',
                                              {
                                                name: 'olist',
                                                icon: '1.'
                                              },
                                              'ulist', 'link']});
        editor.content.innerHTML = htmlInput.value;
      }
    }
  }
}
