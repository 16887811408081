import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = [ "start", "end" ]

  updateLinks() {
    const startDate = document.querySelector('#start_date').value;
    const endDate = document.querySelector('#end_date').value;

    const linksToUpdate = document.querySelectorAll('.link-to-update');

    linksToUpdate.forEach((element) => {
      element.href = element.dataset.initialurl + "?start_date=" + startDate + "&end_date=" + endDate;
    });
  }

}
