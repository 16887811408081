import Picker from "vanilla-picker";

const pickerHandler = function() {

	function Scheme(selector, element, input, color) {
		
		this.selector = document.querySelector(selector);
		this.input 		= document.getElementById(input);
		this.element 	= element;
		
		this.picker = function() {
			return new Picker({
				parent: this.selector, 
				color: color
			});
		};

		this.changeSelectorColor = function(color) {
			this.selector.style.background = color.rgbaString;
			this.input.value = color.rgbaString;
		}

		this.changeMultipleElementsColor = function(color) {
			this.changeSelectorColor(color);
			for(var i = 0; i < this.element.length; i++){
  			this.element[i].style.color = color.rgbaString
  		}
		}

		this.changeSingleElementColor = function(color) {
			this.changeSelectorColor(color);
			this.element.style.background = color.rgbaString;
		}
	}

	let topLayout = {
		background: new Scheme( 
			'#top-bar-selector-background',
			document.querySelector('.bg-custom-navbar'),
			'account_account_color_attributes_top_bg',
			'#7266ba'
		),
		text: new Scheme(
			'#top-bar-selector-text',
			document.getElementsByClassName('menu-top'),
			'account_account_color_attributes_top_txt',
			'#fff'
		)
	}

	let topLeftLayout = {
		background: new Scheme( 
			'#top-bar-selector-background-left',
			document.querySelector('.bg-custom-navbar-brand'),
			'account_account_color_attributes_top_left_bg',
			'#7266ba'
		),
		text: new Scheme(
			'#top-bar-selector-text-left',
			document.getElementsByClassName('text-navbar-brand'),
			'account_account_color_attributes_top_left_txt',
			'#fff'
		)
	}

	let asideLayout = {
		background: new Scheme( 
				'#aside-bar-selector-background',
				document.querySelector('.bg-custom-aside'),
				'account_account_color_attributes_aside_left_bg',
				'#e4eaec'
		),
		text: new Scheme(
				'#aside-bar-selector-text',
				document.getElementsByClassName('menu-left'),
				'account_account_color_attributes_aside_left_txt',
				'#58666e'
		),
		icon: new Scheme(
				'#aside-bar-selector-icon',
				document.getElementsByClassName('icon'),
				'account_account_color_attributes_aside_left_icon',
				'#7266ba'
		)
	}

	asideLayout.background.picker().onChange = (color) => asideLayout.background.changeSingleElementColor(color);
	asideLayout.text.picker().onChange 			 = (color) => asideLayout.text.changeMultipleElementsColor(color);
	asideLayout.icon.picker().onChange 			 = (color) => asideLayout.icon.changeMultipleElementsColor(color);

	topLayout.background.picker().onChange 	= (color) => topLayout.background.changeSingleElementColor(color);
	topLayout.text.picker().onChange = (color) => topLayout.text.changeMultipleElementsColor(color);

	topLeftLayout.background.picker().onChange 	= (color) => topLeftLayout.background.changeSingleElementColor(color);
	topLeftLayout.text.picker().onChange = (color) => topLeftLayout.text.changeMultipleElementsColor(color);
};

document.addEventListener('turbolinks:load', pickerHandler);