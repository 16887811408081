import ApplicationController from './application_controller'

export default class extends ApplicationController {
	sort(event) {
		let element = document.getElementById("contents-list");
		let contentElements = document.getElementsByClassName("content-item");
		let contents = Array.from(contentElements).map((content, index) => {
			return { id: content.dataset.contentId, position: (index +1 ) }
		});
		if (element && element.dataset.sortClass == 'folder') {
      this.stimulate('FoldersReflex#sort', contents);
    } else {
		  this.stimulate('ContentsReflex#sort', contents);
    }
	}

}
