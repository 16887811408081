import ApplicationController from './application_controller'

export default class extends ApplicationController {
	sort(event) {
		let element = document.getElementById("folders-list");
		let folderElements = document.getElementsByClassName("folder-item");
		let folders = Array.from(folderElements).map((folder, index) => {
			return { id: folder.dataset.folderId, position: (index +1 ) }
		});

    this.stimulate('FoldersReflex#sort', folders);
	}

	beforeSort() {
		document.getElementById("wait").innerHTML = "&nbsp;A recarregar...";
	}

	reflexSuccess() {
		location.href = `${location.origin}${location.pathname}?activetab=library`;
	}
}
