const uploadReplacer = function() {
  let uploads = document.querySelectorAll("input[type='file']");

  let i18n = document.getElementById('i18n-config');

  uploads.forEach(function(upload) {
    if (!upload.classList.contains('hidden')) {
      upload.classList.add('hidden');

      let editContainer = document.createElement("div");
      let editIcon = document.createElement("i");
      let editText = '';

      if (i18n && (i18n.dataset.locale == 'pt' || i18n.dataset.locale == 'br')) {
        editText = ' Editar';
      } else {
        editText = ' Edit';
      }

      editIcon.classList.add('fa');
      editIcon.classList.add('fa-pencil');

      editContainer.append(editIcon);
      editContainer.append(editText);

      // click on edit link open file system
      editContainer.addEventListener('click', function(){
        upload.click();
      })

      // add edit link after upload
      upload.parentNode.insertBefore(editContainer, upload.nextSibling);

      upload.addEventListener('change', function(){
        let filename = upload.value;
        filename = filename.replace('C:\\fakepath\\', ' ');
        editContainer.append(filename);
      })
    }
  })

};

const uploadHandler = function() {
  uploadReplacer();
  $('body').on('shown.bs.modal', uploadReplacer);
}


document.addEventListener('turbolinks:load', uploadHandler);
