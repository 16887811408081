// Default SortableJS
import { Sortable, MultiDrag, Swap, OnSpill, AutoScroll } from "sortablejs";

const sortableHandler = function() {
	var folders = document.getElementById("folders-list");
	var contents = document.getElementById("contents-list");

	if (folders != undefined) {
		var sortableFolders = Sortable.create(folders, {
			animation: 150
		});
	}

	if (contents != undefined) {
		var sortableContents = Sortable.create(contents, {
			animation: 100
		});
	}
};

document.addEventListener('turbolinks:load', sortableHandler);