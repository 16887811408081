import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
  }

  reply() {
    let replyingToElement = document.getElementById('replying-to');
    if (replyingToElement) {
      document.getElementById('replying-to-name').textContent = this.element.dataset.userName;
      replyingToElement.classList.remove('hide');
      document.getElementById('parent_comment_id').value = this.element.dataset.parentCommentId;
      document.getElementById('comment_text').focus();
    }
  }
}
